<template>
  <div class="property-custom-categories-add animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2 pb-1">
          <h4 class="mainTitle lineBottom">
            Property Custom Categories Content
          </h4>
          <div class="main-contents">
            <fd-input
              class="mt-2"
              type="text"
              :label="`Name`"
              v-model="propertyCustomCategory.name"
              required
            />
            <fd-textarea
              class="mt-2"
              :label="`Description`"
              v-model="propertyCustomCategory.description"
            />
          </div>
          <div class="text-right px-2 mt-4 mb-2">
            <button type="submit" class="btn main">Update</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import propertyCategoriesEnums from "@/enums/propertyCategory";
import { CustomCategoryModel } from "@/models";

export default {
  name: "property-custom-categories-edit",
  data() {
    return {
      propertyCustomCategory: {
        name: "",
        description: ""
      }
    };
  },
  computed: {
    propertyCategories() {
      const temp = [];

      for (let x of Object.entries(propertyCategoriesEnums)) {
        temp.push({
          id: x[1],
          name: x[1]
        });
      }

      return temp;
    }
  },
  mounted() {
    this.loadCurrentCategory();
  },
  methods: {
    async loadCurrentCategory() {
      const id = this.$route.params.id;
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch(
          "managePropertyCustomCategories/getPropertyCustomCategory",
          id
        );

        this.propertyCustomCategory = CustomCategoryModel.getCustomCategoryDetailResponse(
          this._.cloneDeep(request)
        );

        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    },
    async submitForm() {
      const id = this.$route.params.id;
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch(
          "managePropertyCustomCategories/updatePropertyCustomCategories",
          {
            id: id,
            data: CustomCategoryModel.postCustomCategoryDetailPayload(
              this.propertyCustomCategory
            )
          }
        );

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Property custom category updated successfully."
        });

        this.$router.push({ path: "/manage-property-custom-categories" });
        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: `An error occured. ${error.response.data.message}`
        });
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.property-custom-categories-edit {
  form {
    @extend %formDesign;
  }
}
</style>